.Container {
  display: flex;
  gap: 0;
  flex-direction: column;
  grid-auto-rows: max-content;
  box-sizing: border-box;
  appearance: none;
  outline: none;
  height:100%;
  background-color: transparent;
  font-size: 1em;
  padding: 10px;
}
.Container:nth-child(1) {
  min-width: 50%;
  width: 50%;
  border:1px solid #e7e7e7;
}
.Container:nth-child(2) {
  max-width: 50%;
  width: 50%;
  background-color: #f2f5f8;
}
.Container ul {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(var(--columns, 1), 1fr);
  list-style: none;
  padding: 20px;
  margin: 0;
  padding-top: 0;
  margin-top: 20px;

}
.Container:nth-child(2) ul{
  background-color: #f2f5f8;
}

.Container .ContentBox {
  display: flex;
  gap: 6px;
  flex-direction: column;
  grid-auto-rows: max-content;
  box-sizing: border-box;
  appearance: none;
  outline: none;
  min-width: calc(50% - 8px);
  min-height: calc(100% - 60px);
  height: calc(100% - 60px);
  max-height: calc(100% - 60px);
  transition: background-color 350ms ease;
  background-color: transparent;
  font-size: 1em;

  /* scrollbar  */
  overflow-y: scroll;
  scrollbar-width: 8px;
  scrollbar-color: rgba(83, 130, 161, 0.3) transparent;

  /* Styling for the scrollbar thumb */
  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(83, 130, 161, 0.3);
    border-radius: 4px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #888;
  }

  /* Styling for the scrollbar track */
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
}
.Container.placeholder {
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.5);
  background-color: transparent;
  border-style: dashed;
  border-color: rgba(0, 0, 0, 0.08);
}
.Container.placeholder:hover {
  /* border-color: rgba(0, 0, 0, 0.15); */
}
.Container.hover {
  /* background-color: #ebebeb; */
}
.Container.unstyled {
  overflow: visible;
  background-color: transparent !important;
  border: none !important;
}
.Container.horizontal {
  width: 100%;
}
.Container.horizontal ul {
  grid-auto-flow: column;
}
.Container.shadow {
  box-shadow: 0 1px 10px 0 rgba(34, 33, 81, 0.1);
}
.Container:focus-visible {
  border-color: transparent;
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0), 0 0px 0px 2px #4c9ffe;
}
.Header {
  width: 100%;
  display: flex;
  padding: 20px 8px 8px 24px;
  align-items: start;
  justify-content: start;
  flex-direction: column;
  gap: 8px;
  font-size: 18px;
  font-weight: 500;
  background-color: transparent;
}


.Actions {
  display: flex;
}
.Actions > *:first-child:not(:last-child) {
  opacity: 0;
}
.Actions > *:first-child:not(:last-child):focus-visible {
  opacity: 1;
}
